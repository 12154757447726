import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

export const listFilesInFolder = async (folderName) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Prefix: `images/${folderName}`,
  };

  try {
    const data = await s3Client.send(new ListObjectsV2Command(params));
    const files = data?.Contents?.map(
      (file) =>
        `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.Key}`
    );
    return files;
  } catch (error) {
    console.error("Error listing files:", error);
    return [];
  }
};

const getFileExtension = async (id) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Prefix: `images/${id}`,
    MaxKeys: 1,
  };
  try {
    const data = await s3Client.send(new ListObjectsV2Command(params));
    // console.log("data",data)

    if (data?.Contents?.length > 0) {
      //   console.log("data.Contents",data.Contents)
      const firstFileKey = data.Contents[0].Key;
      const extension = firstFileKey.split(".").pop(); // Extract the file extension
      return extension;
    } else {
      return "jpg"; // No files found
    }
  } catch (error) {
    console.error("Error retrieving file extension:", error);
    return "jpg";
  }
};

export default getFileExtension;
