import React, { useEffect, useState } from "react";

import ItemDetail from "./pages/ItemDetail";
import "./App.css";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";
import getFileExtension from "./helpers/bucket";
const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const App = () => {
  const [type, setType] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const url = window.location.href;
    const idFromUrl = url.substring(url.lastIndexOf("/") + 1);
    if (idFromUrl) {
      (async () => {
        const nwType = await getFileExtension(idFromUrl);
        setType(nwType);

        // console.log("nwType", nwType);
      })();
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        {type && <Route path="/:id" element={<ItemDetail type={type} />} />}
      </Routes>
    </>
  );
};

export default App;
